import React from 'react';
import styled from 'styled-components';
import {
  RiInformationLine as infoIcon,
  RiAlertLine as warningIcon,
  RiCheckLine as successIcon,
} from 'react-icons/ri';

export default function Callout({ variant = 'info', title, children }) {
  let Component;
  let icon;

  if (variant === 'info') {
    Component = InfoCallout;
    icon = infoIcon;
  } else if (variant === 'success') {
    Component = SuccessCallout;
    icon = successIcon;
  } else if (variant === 'warning') {
    Component = WarningCallout;
    icon = warningIcon;
  } else {
    console.error(`Unrecognized Callout type: ${variant}`);
    Component = InfoCallout;
    icon = infoIcon;
  }

  const IconWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, 0);
    padding: 4px;
    background: var(--color-background);
    border-radius: 50%;

    ${InfoCallout} & {
      color: var(--color-info);
    }
    ${SuccessCallout} & {
      color: var(--color-success);
    }
    ${WarningCallout} & {
      color: var(--color-warning);
      border-radius: 25% 25%;
    }
  `;

  const Icon = styled(icon)``;
  return (
    <Component>
      <IconWrapper>
        <Icon size="32px"></Icon>
      </IconWrapper>
      {title && <Title>{title}</Title>}
      {children}
    </Component>
  );
}
export const BaseWrapper = styled.aside`
  position: relative;
  padding-top: 24px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 24px;
  font-size: 16px;
  margin-top: 48px;
  margin-bottom: 64px;
  border-left: 6px solid;
  border-radius: 6px 6px 6px 3px;

  @media (min-width: 1150px) {
    margin-left: -32px;
    margin-right: -32px;
  }

  & > *:last-child {
    margin-bottom: 0 !important;
  }
`;

const Title = styled.strong`
  display: block;
  font-size: 18px;
  margin-bottom: 8px;
`;

export const InfoCallout = styled(BaseWrapper)`
  background: var(--color-muted);
  border-color: var(--color-info);
`;

export const SuccessCallout = styled(BaseWrapper)`
  background: var(--color-success-background);
  border-color: var(--color-success);
`;

export const WarningCallout = styled(BaseWrapper)`
  background: var(--color-alert-background);
  border-color: var(--color-alert);
`;
