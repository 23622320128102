import styled from 'styled-components';
import { device } from '../../utils/breakpoints';

const DeskTopOnly = styled.div`
  display: none;
  @media ${device.medium} {
    display: block;
  }
`;

export default DeskTopOnly;
