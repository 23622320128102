const size = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
};
/**
 * Enum for device sizes in min-width media query
 * @namespace
 * @readonly
 * @enum {string}
 */
export const device = {
  /** 640px */
  small: `(min-width: ${size.sm})`,
  /** 768px */
  medium: `(min-width: ${size.md})`,
  /** 1024px */
  large: `(min-width: ${size.lg})`,
  /** 1280px */
  xlarge: `(min-width: ${size.xl})`,
};
