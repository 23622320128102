import React from 'react';
import styled from 'styled-components';
import Button from '../Button';
import Avatar from '../Hero/avatar';
import DeskTopOnly from '../DesktopOnly/DeskTopOnly';
import { device } from '../../utils/breakpoints';
import { trackCustomEvent } from '../../utils/';
import MaxWidthWrapper from '../MaxWidthWrapper';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  width: 100vw;

  background-color: var(--color-panel);

  font-family: var(--font-family-ui);

  @media ${device.medium} {
    /* padding: 16px 96px;
    margin-left: -128px;
    margin-right: -128px; */
  }
`;
const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;
const handleSubmit = (event) => {
  trackCustomEvent('subscribe-form', 'submit', 'page', window.location);
};

const Input = styled.input`
  user-select: text;

  align-items: center;
  align-content: center;
  background: var(--color-input);
  outline: none;
  border: none;
  box-shadow: 0 2px 0 hsla(220, 0%, 100%, 0.15),
    inset 0 2px 2px hsla(220, 0%, 0%, 0.1);
  height: 40px;
  width: 100%;
  font-size: 18px;
  /* box-sizing: border-box; */
  border-radius: 8px;
  margin-right: 16px;
  flex: 1;
  font-size: var(--font-size-sm);
  color: var(--color-text);
  padding-left: 8px;
  padding-right: 8px;
  overflow-x: hidden;
  min-width: 140px;

  ::placeholder {
    color: var(--color-text);
    font-size: 18px;
  }
  margin-bottom: 24px;
  @media ${device.medium} {
    /* margin-bottom: 0; */
    margin-bottom: 18px;
  }
`;
const Title = styled.h2`
  font-size: var(--font-size-md);
  margin-bottom: 18px;
  @media ${device.large} {
    font-size: var(--font-size-md);
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 4px 32px;

  margin: 0 auto;
  margin-top: 32px;
  justify-content: center;
  align-items: center;
  max-width: 768px;
  @media ${device.medium} {
    justify-content: space-evenly;
    flex-direction: row;
  }
`;

const baseText = (
  <p>
    My goal with this blog is to create meaningful content for web devs, and my
    newsletter is no different! It's sent monthly, and includes upcoming posts,
    and other stuff I think you'd enjoy. I will
    <strong> never spam you</strong> and you can
    <strong> unsubscribe at any time</strong>.
  </p>
);

const SubscribeForm = ({
  title = 'A web development newsletter that you will like',
  children,
}) => {
  const text = children ?? baseText;

  return (
    <Wrapper>
      <MaxWidthWrapper>
        <Title>{title}</Title>
        <p>{text}</p>
        <Form
          action="https://app.convertkit.com/forms/1487497/subscriptions"
          method="post"
          target="popupwindow"
          onSubmit={handleSubmit}
        >
          <Row>
            <Input
              placeholder="First Name"
              type="text"
              name="fields[first_name]"
              size="24"
              onFocus={() =>
                trackCustomEvent('subscribe-form', 'focus', 'first_name')
              }
            />
            <Input
              placeholder="Your Email"
              type="email"
              name="email_address"
              required
              onFocus={() =>
                trackCustomEvent('subscribe-form', 'focus', 'email')
              }
              size="24"
            />
          </Row>
          <input type="hidden" value="1" name="embed" />

          <Button type="submit">Subscribe</Button>
        </Form>
      </MaxWidthWrapper>
    </Wrapper>
  );
};

export default SubscribeForm;
