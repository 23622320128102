import React from 'react';
import styled from 'styled-components';

const MaxWidthWrapper = styled.div`
  position: relative;

  width: 100%;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
`;

export default MaxWidthWrapper;
