import React from 'react';
import styled from 'styled-components';
import Link from './Link/Link';

const A = styled(Link)`
  font-weight: var(--font-weight-medium);
  color: var(--color-primary);
`;

//There a hydration issue that will make links go to the wrong locations. If we are not in the browser , don't render links
export default ({ children, ...delegated }) => <A {...delegated}>{children}</A>;
