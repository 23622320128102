import Highlight, { defaultProps } from 'prism-react-renderer';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { copyToClipboard } from '../utils/copy-to-clipboard';

const theme = {
  plain: {
    color: 'var(--color-text)',
    backgroundColor: 'var(--color-muted)',
  },
  styles: [
    {
      types: ['comment'],
      style: {
        color: 'rgb(105, 112, 152)',
        fontStyle: 'italic',
      },
    },
    {
      types: ['string', 'inserted'],
      style: {
        color: 'rgb(195, 232, 141)',
      },
    },
    {
      types: ['number'],
      style: {
        color: 'rgb(247, 140, 108)',
      },
    },
    {
      types: ['builtin', 'char', 'constant', 'function'],
      style: {
        color: 'var(--color-secondary)',
      },
    },
    {
      types: ['punctuation', 'selector'],
      style: {
        color: 'rgb(199, 146, 234)',
      },
    },
    {
      types: ['variable'],
      style: {
        color: 'rgb(191, 199, 213)',
      },
    },
    {
      types: ['class-name', 'attr-name'],
      style: {
        color: 'rgb(255, 203, 107)',
      },
    },
    {
      types: ['tag', 'deleted'],
      style: {
        color: 'rgb(255, 85, 114)',
      },
    },
    {
      types: ['operator'],
      style: {
        color: 'rgb(137, 221, 255)',
      },
    },
    {
      types: ['boolean'],
      style: {
        color: 'rgb(255, 88, 116)',
      },
    },
    {
      types: ['keyword'],
      style: {
        fontStyle: 'italic',
      },
    },
    {
      types: ['doctype'],
      style: {
        color: 'rgb(199, 146, 234)',
        fontStyle: 'italic',
      },
    },
    {
      types: ['namespace'],
      style: {
        color: 'rgb(178, 204, 214)',
      },
    },
    {
      types: ['url'],
      style: {
        color: 'rgb(221, 221, 221)',
      },
    },
  ],
};

export const Pre = styled.pre`
  position: relative;
  text-align: left;
  padding: 8px;
  overflow: auto;
  /* border: 1px solid rgba(0, 0, 0, 0.5); */

  box-shadow: inset 0 2px 2px hsla(0, 0%, 0%, 0.1),
    0 2px 0 hsla(0, 0%, 100%, 0.1);
  border-radius: 3px;
  & .token-line {
    line-height: 1.3rem;
  }

  font-family: var(--font-family-mono);
  font-size: 16px;
  outline-offset: 2px;
  margin-left: -8px;
  margin-right: -8px;
  margin-top: 48px;
  /* border-radius: 8px; */
  border-radius: 8px 0px 8px 8px;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  z-index: 1;
  padding: 32px;
`;

// Create the keyframes
const animateUp = keyframes`
  from {
    transform: translateY(0);

  }

  to {

    transform: translateY(-100%);

  }
`;
const Wrapper = styled.div`
  position: relative;
  /* top: 0; */
  /* left: -240px; Move this out to the left of the site body, leaving a 20px gutter */
`;
const CodeTab = styled.span`
  position: absolute;
  z-index: 100;
  top: 0px;
  right: -8px;
  transform: translateY(-100%);
  font-size: 12px;
  height: 24px;
  text-transform: uppercase;
  color: var(--color-text);
  font-weight: var(--font-weight-light);
  font-family: var(--font-family-mono);
  padding: 4px 8px 0px;
  background: var(--color-panel);
  /* box-shadow: inset 0 2px 2px hsla(0, 0%, 0%, 0.1); */
  border-radius: 4px 4px 0px 0px;
  pointer-events: none;
`;

const Code = ({ codeString, language }) => {
  return (
    <Highlight
      {...defaultProps}
      code={codeString}
      language={language}
      theme={theme}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <Wrapper>
          <CodeTab>{language}</CodeTab>
          <Pre className={className} style={style}>
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </Pre>
        </Wrapper>
      )}
    </Highlight>
  );
};
export default Code;
