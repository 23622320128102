import React from 'react';
import styled from 'styled-components';

const SpacerDiv = styled.div`
  content: '';

  height: ${(props) => (props.amount ? props.amount : 48)}px;
  min-width: 1px;
  min-height: 1px;

  background-color: transparent;
`;

const Spacer = ({ amount }) => {
  return <SpacerDiv amount={amount}></SpacerDiv>;
};

export default Spacer;
