import React, { Component } from 'react';
import styled from 'styled-components';

const ButtonBase = styled.button`
  outline: none;
  transition: all 300ms ease-out;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border-radius: 24px;
  padding: 4px 24px;
  text-align: center;
  cursor: pointer;
  /* box-shadow: 5px 5px 1px rgba(0, 0, 0, 0.5), -1px -2px 10px rgba(0, 0, 0, 0.1); */
  /* background-color: green; */
  background: linear-gradient(
    145deg,
    var(--color-green-500),
    var(--color-mint-500)
  );

  color: #fff;
  height: 40px;
  font-size: var(--font-size-sm);
  box-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.2);
  &:hover {
    transform: scale(1.1);
    background: linear-gradient(
      145deg,
      var(--color-green-300),
      var(--color-mint-300)
    );
    color: #fff;
  }
`;

const Button = ({ style = 'default', children, ...delegated }) => {
  return <ButtonBase {...delegated}>{children}</ButtonBase>;
};

export default Button;
