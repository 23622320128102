import { MDXProvider } from '@mdx-js/react';
import React from 'react';
import theme from 'prism-react-renderer/themes/palenight';

import Code from './src/components/Code';
import Spacer from './src/components/Spacer';
import TextLink from './src/components/TextLink';
import Callout from './src/components/Callout';

import Center from './src/components/MDX/Center';
import SubscribeForm from './src/components/SubscribeForm/SubscribeForm';

const components = {
  a: TextLink,
  'p.inlineCode': (props) => (
    <code
      style={{
        backgroundColor: theme.plain.backgroundColor,
        color: theme.plain.color,
      }}
      {...props}
    />
  ),

  pre: ({ children: { props } }) => {
    if (props.mdxType === 'code') {
      return (
        <Code
          codeString={props.children.trim()}
          language={props.className && props.className.replace('language-', '')}
          {...props}
        />
      );
    }
  },
  Callout,
  Center,
  SubscribeForm,
  Spacer,
};
export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>{element}</MDXProvider>
);
