export const slugify = (str = '') => {
  let slug = str
    .replace(/\s+/g, '-')
    .replace(/[^0-9A-Za-z-]/g, '')
    .toLowerCase();

  return slug;
};

export const isEmpty = (obj) => Object.keys(obj).length === 0;

export const sortBy = (arr, key, comparator) => {
  let comparatorToUse =
    comparator ||
    function (a, b) {
      if (a > b) {
        return 1;
      } else if (a < b) {
        return -1;
      } else {
        return 0;
      }
    };

  return arr.sort((a, b) => {
    return comparatorToUse(a[key], b[key]);
  });
};

export const getInterpolatedValue = (y1, y2, ratio) => {
  // We're assuming that `ratio` is a value between 0 and 1.
  // If this were a graph, it'd be our `x`, and we're trying to solve for `y`.
  // First, find the slope of our line.
  const slope = y2 - y1;

  return slope * ratio + y1;
};

export const capitalize = (str) => str[0].toUpperCase() + str.slice(1);

export const hyphenate = (str) => str.replace(/([A-Z])/g, '-$1').toLowerCase();

/**
 * Create custom Matomo event.
 *
 * @see https://matomo.org/docs/event-tracking/
 */
export const trackCustomEvent = (
  eventCategory,
  eventAction,
  eventName,
  eventValue = null
) => {
  if (process.env.NODE_ENV === 'production' || window.dev === true) {
    if (!window._paq) return;

    const { _paq, dev } = window;

    _paq.push([
      `trackEvent`,
      eventCategory,
      eventAction,
      eventName,
      eventValue,
    ]);

    if (dev) {
      console.debug(
        `[Matomo] event tracked, category: ${eventCategory}, action: ${eventAction}, name: ${eventName}, value: ${eventValue}`
      );
    }
  }
};
